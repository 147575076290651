import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

import type { Theme } from "@mui/material/styles";

// const font = `Akatab`;
// const fontUrl = `https://fonts.googleapis.com/css2?family=${font}:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"`;

// const font = "Figtree, sans-serif";
// const fontUrl =
//   "https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap";

export const font = "Figtree";
export const fontUrl =
  "https://fonts.googleapis.com/css2?" +
  "family=Akatab:wght@100;200;300;400;500;600;700;800;900&" +
  "family=Figtree:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&" +
  "family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&" +
  "display=swap";

export const typoGraphySx = {
  paddingLeft: (curTheme: Theme) => curTheme.spacing(3),
  paddingRight: (curTheme: Theme) => curTheme.spacing(3),
  marginTop: (curTheme: Theme) => curTheme.spacing(1),
  marginBottom: (curTheme: Theme) => curTheme.spacing(3),
};

export const theme = createTheme({
  /* 
    https://www.colorhexa.com/13361c
    #13361C
    #273d41
    https://icolorpalette.com/imagepalette/green_grass_plant
  */
  palette: {
    primary: {
      main: "#4c4c4c", // navigation and footer ->  676767 | 4b574f |  | 594530 | 2E2D2E
      dark: "#15955d", // slogan -> #436c0e | 2E8B57 | 2E8F57 | 6a8949 | 2E8F57 | 01681D | 178f28 | 0b9051
      light: "#f4f2ef", //	Contact -> #E0E0E0  // #ECECEC
    },
    secondary: {
      main: "#ECECEC", // about me #f5f5f5 | ECECEC
      // dark: "#2779a7",
      light: "#f4f2ef", // vita, portfolio fafafa
    },
    error: {
      main: red.A400,
    },
    text: { primary: "#000000", secondary: "#ffffff" },
  },
  typography: {
    fontFamily: font,
    fontWeightRegular: 400,
    fontSize: 18,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {},
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "3rem",
        },
      },
    },
  },
});
