import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import { typoGraphySx } from "../../definitions/theme";

import type { PageProperties } from "../../types/PageProperties";
import type { Theme } from "@mui/material/styles";

export function Vita(props: PageProperties) {
  const { backgroundColor } = props;

  return (
    <Grid
      id="vita"
      container
      sx={{
        backgroundColor,
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Grid
        size={12}
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          component={"h2"}
          variant={"h4"}
          gutterBottom
          sx={{ paddingTop: (curTheme: Theme) => curTheme.spacing(5) }}
        >
          Ressourcen
        </Typography>
      </Grid>
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
          sx={{
            paddingBottom: (curTheme: Theme) => curTheme.spacing(10),
          }}
        >
          <Typography
            component={"h3"}
            variant={"h5"}
            gutterBottom
            sx={{
              textAlign: "center",
            }}
          >
            Ausbildung & Erfahrung
          </Typography>
          <Typography sx={typoGraphySx}>
            Zehnjährige Tätigkeit als Unternehmensberater mit dem Schwerpunkt
            &quot;Agile Enterprise Transformation&quot;, zuletzt als
            Intrapreneur über sechs Jahre für Accenture als Senior Manager und
            Senior Agile Advisor.
          </Typography>
          <Typography sx={typoGraphySx}>
            <Link
              to="https://de.wikipedia.org/wiki/Systemische_Transaktionsanalyse"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Systemische ressourcenorientierte Transaktion&shy;sanalyse
            </Link>{" "}
            bei Bertine Kessel (Abschluss als DGTA Berater und Coach)
          </Typography>
          <Typography sx={typoGraphySx}>
            Studium der Informatik und Philosophie an der Uni Bremen und der
            Fernuni Hagen (Abschluss als Dipl.-Inf.)
          </Typography>
          <Typography sx={typoGraphySx}>
            Sieben Jahre in der Kinder- und Jugendhilfe mit schwer erziehbaren
            Kindern und Jugendlichen
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }} sx={{ paddingLeft: 2 }}>
          <Typography
            component={"h3"}
            variant={"h5"}
            gutterBottom
            sx={{
              textAlign: "center",
            }}
          >
            Zertifizierung & Auszeichnung
          </Typography>
          <Typography sx={typoGraphySx}>
            <Link
              to="https://dgta.de/ta-wir-bilden-weiter/"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Coach (Deutsche Gesellschaft für Transaktionsanalyse)
            </Link>
          </Typography>
          <Typography sx={typoGraphySx}>
            <Link
              to="https://dgta.de/ta-wir-bilden-weiter/"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              TA Berater (Deutsche Gesellschaft für Transaktionsanalyse)
            </Link>
          </Typography>
          <Typography sx={typoGraphySx}>
            <Link
              to="https://management30.com/"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Management 3.0 Facilitator
            </Link>
          </Typography>
          <Typography
            sx={{
              ...typoGraphySx,
              marginBottom: (curTheme: Theme) => curTheme.spacing(1),
            }}
          >
            <Link
              to="https://leanchange.org/"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Lean Change Agent & Professional
            </Link>
          </Typography>
          <Typography sx={typoGraphySx}>
            <Link
              to="https://scaledagileframework.com/"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Scaled Agile Framework Program Consultant (SPC6)
            </Link>
          </Typography>

          <Typography sx={typoGraphySx} style={{ paddingBottom: 0, margin: 0 }}>
            ICAgile Certified Professional (
            <Link
              to="https://www.icagile.com/certification/agile-coaching"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              ACC
            </Link>
            ,{" "}
            <Link
              to="https://www.icagile.com/certification/business-agility-foundations"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              BAF
            </Link>
            ,{" "}
            <Link
              to="https://www.icagile.com/certification/agile-coaching"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              ACC
            </Link>
            )
          </Typography>
          <Typography sx={typoGraphySx}>
            Accenture Leadership Awards 2016 (Mitarbeiterwahl)
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
