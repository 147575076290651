import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Image from "mui-image";

import { StyledBox } from "../components/StyledBox";

import type { PageProperties } from "../types/PageProperties";
import type { Theme } from "@mui/material/styles";

export function Memberships(props: PageProperties) {
  const { backgroundColor } = props;
  // eslint-disable-next-line no-console
  console.log(`reload: ${backgroundColor}`);
  return (
    <Box
      sx={{
        flexGrow: 1,
        pl: (curTheme: Theme) => curTheme.spacing(4),
        pr: (curTheme: Theme) => curTheme.spacing(4),
        backgroundColor: "white",
      }}
    >
      <Grid
        id="memberships"
        container
        justifyContent={"center"}
        justifyItems={"center"}
        alignItems={"center"}
        alignContent={"center"}
        size={12}
      >
        <StyledBox
          sx={{
            pt: (curTheme: Theme) => curTheme.spacing(5),
            pb: (curTheme: Theme) => curTheme.spacing(5),
          }}
        >
          <Grid container spacing={0} sx={{}}>
            <Grid size={{ md: 4, xs: 12 }}>
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.only("sm")]: {
                    ml: (curTheme: Theme) => curTheme.spacing(30),
                    mr: (curTheme: Theme) => curTheme.spacing(10),
                  },
                })}
              >
                <Image
                  fit="contain"
                  bgColor="inherit"
                  src="./Siegel_TA_Berater.png"
                />
              </Box>
            </Grid>
            <Grid size={{ md: 4, xs: 12 }}>
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.only("sm")]: {
                    ml: (curTheme: Theme) => curTheme.spacing(20),
                    mr: (curTheme: Theme) => curTheme.spacing(20),
                  },
                })}
              >
                <Image
                  fit="contain"
                  src="./ITAA-Member Badges-2024-vector.svg"
                />
              </Box>
            </Grid>
            <Grid size={{ md: 4, xs: 12 }}>
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.only("sm")]: {
                    ml: (curTheme: Theme) => curTheme.spacing(10),
                    mr: (curTheme: Theme) => curTheme.spacing(30),
                  },
                })}
              >
                <Image fit="contain" src="./Siegel_TA-Coach.png" />
              </Box>
            </Grid>
          </Grid>
        </StyledBox>
      </Grid>
    </Box>
  );
}
