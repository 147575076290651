import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { theme } from "../definitions/theme";

import { StyledBox } from "./StyledBox";

const backgroundColor = theme.palette.primary.main;
const textColor = theme.palette.text.secondary;

export function Footer() {
  return (
    <Grid item xs={12} sx={{ backgroundColor: { backgroundColor } }}>
      <StyledBox>
        <Typography
          sx={{
            color: textColor,
            padding: 3,
            fontSize: 15,
          }}
        >
          <Link
            href="/imprint"
            sx={{
              color: "white",
              fontSize: 15,
            }}
          >
            Impressum & Datenschutz
          </Link>{" "}
          &#124; Dominik Elsbroek {new Date().getFullYear()}
        </Typography>
      </StyledBox>
    </Grid>
  );
}
