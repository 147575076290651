import { createContext, useContext } from "react";

export type DevelopmentContextType = {
  development: boolean;
};

export const DevelopmentContext = createContext<
  DevelopmentContextType | undefined
>(undefined);

export const useDevelopmentMode = () => {
  const developmentMode = useContext(DevelopmentContext);

  return developmentMode == null;
};
